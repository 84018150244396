import { handleActions } from 'redux-actions';

// Global actions
import {
  SET_LOADING,
  SET_LANGUAGE,
  CLEAR_API_ERROR,
  SET_API_ERROR,
  TOGGLE_LANGUAGE,
  CREATE_TOAST,
  CLEAR_TOAST,
  SHOW_MODAL,
  RUN_JOYRIDE_TOUR,
  SET_CLICK_ACTION_STATUS,
  SET_CURRENCY_DATA,
  SET_CURRENCY_RATES,
  SET_SAFIRI_CONFIGURATIONS,
} from './action-types';

// Import TypeScript types
import { IGlobalDefaultState } from './types';
import { ENGLISH } from '../../constants';

export const defaultState: IGlobalDefaultState = {
  loading: false,
  apiError: undefined,

  toggleLanguage: true,

  toasters: [],
  toastPosition: 'top-center',

  language: {
    languageTag: ENGLISH,
    isRTL: false,
  },

  modal: {
    show: false,
    html: false,
    bodyText: '',
    modalTitle: '',
    isRedirect: false,

    buttons: [],
    modalBodyClasses: [],

    closeOnBackDrop: false,
    closeButton: false,

    loading: false,
    size: '',
  },

  runJoyRide: {
    run: false,
    stepIndex: 0,
  },
  joyRideActions: {
    isClickActionDone: false,

    isTimetableOpen: false,
    isTimetableRouteSelected: false,

    isDiscountOpen: false,

    isTripsOpen: false,
  },

  currency: {
    // currency exchange rate
    // rate: 1,
    // currency code e.g. TZS, ZMW, KES etc
    // code: CURRENCY.TZS,
    // currency country flag
    // flag: 'flag-tz',
    // currency country name in short e.g. TZ, ZM, KE etc
    // countryShort: 'TZ',
    // currency country name in full e.g. Tanzania, Zambia, Kenya etc
    // countryName: 'Tanzania',
    // currency country region e.g. Africa, Europe, Asia etc
    // countryRegion: 'Africa',
    // currency country subregion e.g. Eastern Africa, Southern Africa etc
    // countrySubregion: 'Eastern Africa',

    // currency exchange rates
    rates: [],
  },
  safiriConfigs: undefined,
};

export const autohide = 5000;

// @ts-ignore
export default handleActions(
  {
    [SET_LOADING]: (state, { payload = false }) => ({
      ...state,
      loading: payload,
    }),
    [SET_LANGUAGE]: (state, { payload = ENGLISH }) => ({
      ...state,
      language: {
        languageTag: payload,
        isRTL: false,
      },
    }),
    [TOGGLE_LANGUAGE]: (state, { payload = true }) => ({
      ...state,
      toggleLanguage: payload,
    }),
    [CREATE_TOAST]: (state, { payload }) => ({
      ...state,
      toasters: [...state.toasters, { autohide, ...payload }],
      // @ts-ignore
      toastPosition: payload.position || 'top-center',
    }),
    [CLEAR_TOAST]: (state) => ({
      ...state,
      toasters: [],
      toastPosition: 'top-center',
    }),
    [SHOW_MODAL]: (state, { payload }) => ({
      ...state,
      modal: { ...state.modal, ...payload },
    }),
    [RUN_JOYRIDE_TOUR]: (state, { payload }) => ({
      ...state,
      runJoyRide: { ...state.runJoyRide, ...payload },
    }),
    [SET_CLICK_ACTION_STATUS]: (
      state,
      {
        payload = {
          isClickActionDone: false,

          isTimetableOpen: false,
          isTimetableRouteSelected: false,

          isDiscountOpen: false,

          isTripsOpen: false,
        },
      },
    ) => ({
      ...state,
      joyRideActions: { ...state.joyRideActions, ...payload },
    }),
    [CLEAR_API_ERROR]: (state) => ({
      ...state,
      apiError: undefined,
    }),
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
    }),
    [SET_CURRENCY_DATA]: (state, { payload }) => ({
      ...state,
      currency: {
        ...(state.currency || {}),
        ...(payload || {}),
      },
    }),
    [SET_CURRENCY_RATES]: (state, { payload }) => ({
      ...state,
      currency: {
        ...(state.currency || {}),
        rates: payload,
      },
    }),
    [SET_SAFIRI_CONFIGURATIONS]: (state, { payload }) => ({
      ...state,
      safiriConfigs: payload,
    }),
  },
  defaultState,
);
