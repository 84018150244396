// import { offline } from '@redux-offline/redux-offline';

import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';

import requestRetry, { ResponseError } from '../../lib/request';

import * as action from './action-types';
import * as routes from '../../constants/routes';

// TypeScript Types
// import { User as IUser } from './user';
import { IState } from '../../types/state';

import { config } from '../../config';
import { setLoggedIn } from './../logon/actions';
import { IUser, IUserUpdates } from '../../types/user';
import {
  getAllStaff,
  resetState as resetCompanyState,
} from '../company/actions';
import { clearToast } from '../global/actions';
import { resetDiscountState } from '../discount/actions';
import { logon } from '../logon/actions';
import { ILogon } from '../hook-form/types';
import { IInputValues } from '../../views/password-reset';
import { IResponseError } from '../../types/errors';

const { REACT_APP_SAFIRI_BOOKING_API_URL } = config;

export const setApiError = createAction(action.SET_API_ERROR);
export const clearApiError = createAction(action.CLEAR_API_ERROR);

export const saveToken = createAction(action.SAVE_TOKEN);
export const saveRefreshToken = createAction(action.SAVE_REFRESH_TOKEN);

export const startUserUpdate = createAction(action.START_USER_UPDATE);
export const saveUser = createAction(action.SAVE_USER);
export const saveLogonUser = createAction(
  action.SAVE_LOGON_USER,
  (updates) => updates,
  (offline) => ({
    offline,
  }),
);
export const endUserUpdate = createAction(action.END_USER_UPDATE);

export const showUserLogoutModal = createAction(action.USER_LOGOUT_MODAL);

export const resetState = createAction(action.RESET_STATE);
export const resetLoading = createAction(action.RESET_LOADING);

export const saveSearchUsers = createAction(action.SAVE_SEARCHED_USERS);

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET and fetch all safiri staff
 *
 * @param requestFn - (Optional) fetcher function
 * @returns {IUser[] | IResponseError} - Returns all safiri staff or response error
 */
export const getSafiriStaff =
  (requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IResponseError | IUser[]> => {
    try {
      // clear api errors
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startUserUpdate());

      // Get all Cities
      const allSafiriStaff = await requestFn<IUser[]>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_ALL_SAFIRI_STAFF}`,
      );

      // End loading
      dispatch(endUserUpdate());

      return allSafiriStaff;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(endUserUpdate());

      // Return the error from the API
      return e as ResponseError;
    }
  };

/**
 *
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will clear the store and logout the user
 *
 * @return {void | ResponseError}
 */
export const logoutUser =
  (/* requestFn = request */) => async (dispatch: Dispatch) => {
    try {
      // This function dispatches a Redux Action that sets "loading: true, loadingSuccess: false" on the Redux store
      dispatch(startUserUpdate());

      // Reset redux store user
      dispatch(resetState());

      // Reset redux store for company
      dispatch(resetCompanyState());

      // reset redux store for company's discounts
      dispatch(resetDiscountState());

      // set loggedIn to false
      dispatch(setLoggedIn(false));

      // clear toast
      dispatch(clearToast());

      // This function dispatches a Redux Action that sets "loading: true, loadingSuccess: false" on the Redux store
      dispatch(endUserUpdate());

      return true;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(endUserUpdate());

      // return the error from the API
      return e;
    }
  };

/**
 *
 * @param email user to find's email
 * @param requestFn request function
 *
 * @returns A responseError or the fetched user
 */
export const findUser =
  (email: string, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IUser | ResponseError> => {
    try {
      // dispatch action to clear api errors
      dispatch(clearApiError());

      // set loading
      dispatch(startUserUpdate());

      // find user
      const response = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${
          routes.GET_USER
        }email=${email.toLowerCase()}`,
      );

      // end update
      dispatch(endUserUpdate());

      // return user
      // @ts-ignore
      return response;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(endUserUpdate());

      // Return the error from the API
      return e as ResponseError;
    }
  };

/**
 *
 * @param email user to find's email
 * @param requestFn request function
 *
 * @returns A responseError or the fetched user
 */
export const findUserByMobile =
  (
    { countryCode, phoneNumber }: { phoneNumber: string; countryCode: string },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IUser | ResponseError> => {
    try {
      // dispatch action to clear api errors
      dispatch(clearApiError());

      // set loading
      dispatch(startUserUpdate());

      // find user
      const response = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.GET_USER}phoneNumber=${phoneNumber}&countryCode=${countryCode}`,
      );

      // end update
      dispatch(endUserUpdate());

      // return user
      // @ts-ignore
      return response;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(endUserUpdate());

      // Return the error from the API
      return e as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP DELETE so that it can delete a user
 *
 * @param {function} requestFn - (Optional) fetcher function
 *
 * @return {boolean | ResponseError}
 */
export const deleteUser =
  (user: IUser, requestFn = requestRetry) =>
  async (
    dispatch: Dispatch,
    getState: any,
  ): Promise<boolean | ResponseError> => {
    try {
      // dispatch action to clear API
      dispatch(clearApiError());

      // Dispatch action to set "loading: true"
      dispatch(startUserUpdate());

      // Delete user
      await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.REMOVE_STAFF}/${user?._id}`,
        {
          method: 'DELETE',
        },
      );

      // End loading
      dispatch(endUserUpdate());

      return true;
    } catch (e) {
      dispatch(setApiError(e));
      dispatch(endUserUpdate());

      // Return the error from the API
      return e as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT so that it can update user values
 *
 * @param {object} updateConfig - User fields to update
 * @param {function} requestFn - (Optional) fetcher function
 *
 * @return {IUser | ResponseError}
 */
export const updateUser =
  (updateConfig: IUserUpdates, requestFn = requestRetry) =>
  async (dispatch: Dispatch, getState: any): Promise<IUser | ResponseError> => {
    try {
      const {
        user: { details },
        company: { details: company },
      } = getState() as IState;

      // clear api errors
      dispatch(clearApiError());

      // Start loading
      dispatch(startUserUpdate());

      // Update user
      const updatedUser = await requestFn<IUser>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.EDIT_PROFILE}${details?._id}`,
        {
          method: 'PUT',
          body: JSON.stringify(updateConfig),
        },
      );

      // Save new updated user
      dispatch(saveUser(updatedUser));

      // reload all company staff
      dispatch(getAllStaff(company?.tinNumber || '') as any);

      // End loading
      dispatch(endUserUpdate());

      return updatedUser;
    } catch (err) {
      dispatch(setApiError(err));
      dispatch(endUserUpdate());

      // Return the error from the API
      return err as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a message so that it can request a password reset code
 *
 * @param {string} userPhone - Phone number to send the message to
 * @param {function} requestFn - (Optional) fetcher function
 *
 * @return {IUser | ResponseError}
 */

export const forgotPassword =
  (
    { countryCode, phoneNumber }: { countryCode: string; phoneNumber: number },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IUser | ResponseError> => {
    try {
      // Dispatch action to set "loading: true"
      dispatch(startUserUpdate());

      // Send message with a reset code to enable resetting password
      const response = await requestFn<IUser>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.PASSWORD}?countryCode=${countryCode}&phoneNumber=${phoneNumber}`,
      );

      // End loading
      dispatch(endUserUpdate());

      // Return results of the request
      return response;
    } catch (e) {
      dispatch(setApiError(e as ResponseError));
      dispatch(endUserUpdate());

      // Return the error from the API
      return e as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT so that it can reset a password
 *
 * @param {string} userPhone - Phone number to reset password on
 * @param {string} code - Password reset code
 * @param {string} password - New password to be set
 * @param {function} requestFn - (Optional) fetcher function
 *
 * @return {IUser | ResponseError} User - A user or API error
 */
export const resetPassword =
  (
    { userPhone, countryCode, code, password }: IInputValues,

    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IUser | ResponseError> => {
    try {
      // Dispatch action to set "loading: true"
      dispatch(startUserUpdate());

      const response = await requestFn<IUser>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.PASSWORD}?countryCode=${countryCode}&phoneNumber=${userPhone}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            code,
            password,
          }),
        },
      );

      const logonValues: ILogon = {
        countryCode: countryCode,
        phoneNumber: userPhone,
        password: password,
      };

      await dispatch(logon(logonValues) as any);

      // End Loading
      dispatch(endUserUpdate());

      // Return results of the request
      return response;
    } catch (e) {
      dispatch(setApiError(e as ResponseError));
      dispatch(endUserUpdate());

      // Return the error from the API
      return e as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT to verify user email
 *
 * @param {string} email - Email to verify
 * @param {string} token - Email verification token
 * @param {function} requestFn - (Optional) fetcher function
 *
 * @return {IUser | ResponseError} User - A user or API error
 */
export const verifyEmail =
  (email: string, token: string, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<IUser | ResponseError> => {
    try {
      // Start loading
      dispatch(startUserUpdate());

      // Send email with a reset code to enable resetting password
      const response = await requestFn<IUser>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.VERIFY_EMAIL}${email}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            token,
          }),
        },
      );

      // Save new verified user
      dispatch(saveUser(response));

      // End loading
      dispatch(endUserUpdate());

      return response;
    } catch (err) {
      dispatch(setApiError(err));
      dispatch(endUserUpdate());

      // Return error from the API
      return err as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET to request an email verification code
 *
 * @param {string} email - Email to reset password on
 * @param {function} requestFn - (Optional) fetcher function
 *
 * @return {boolean | ResponseError} - API error or true (to affirm success of verification code being sent to user's email)
 */
export const requestEmailVerificationCode =
  (email: string, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<boolean | ResponseError> => {
    try {
      // Start loading
      dispatch(startUserUpdate());

      // Send email with a reset code to enable resetting password
      await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.VERIFY}?email=${email}`,
      );

      // End loading
      dispatch(endUserUpdate());

      return true;
    } catch (err) {
      dispatch(setApiError(err));
      dispatch(endUserUpdate());

      // Return error from the API
      return err as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP PUT to verify user phone number
 *
 * @param {string} phone number - phone number to verify
 * @param {string} country code - country code of the phone number
 * @param {string} token - Phone number verification token
 * @param {function} requestFn - (Optional) fetcher function
 *
 * @return {IUser | ResponseError} User - A user or API error
 */
export const verifyPhoneNumber =
  (
    phoneNumber: string,
    countryCode: string,
    token: string,
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<IUser | ResponseError> => {
    try {
      // Start loading
      dispatch(startUserUpdate());

      // Dispatch action that sets "apiError: undefined"
      dispatch(clearApiError());

      // Send email with a reset code to enable resetting password
      const response = await requestFn<IUser>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.VERIFY_MOBILE}phoneNumber=${phoneNumber}&countryCode=${countryCode}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            token,
          }),
        },
      );

      // Save new verified user
      dispatch(saveUser(response));

      // End loading
      dispatch(endUserUpdate());

      return response;
    } catch (err) {
      dispatch(setApiError(err));
      dispatch(endUserUpdate());

      // Return error from the API
      return err as ResponseError;
    }
  };

/**
 * A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET to request a phone number verification code
 *
 * @param {string} phone number - phone number to resend verification code to
 * @param {string} country code - country code of the phone number to send verification code to
 * @param {function} requestFn - (Optional) fetcher function
 *
 * @return {boolean | ResponseError} - API error or true (to affirm success of verification code being sent to user's email)
 */
export const requestPhoneNumberVerificationCode =
  (phoneNumber: string, countryCode: string, requestFn = requestRetry) =>
  async (dispatch: Dispatch): Promise<boolean | ResponseError> => {
    try {
      // Start loading
      dispatch(startUserUpdate());

      // Dispatch action that sets "apiError: undefined"
      dispatch(clearApiError());

      // Send email with a reset code to enable resetting password
      await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.VERIFY}?phoneNumber=${phoneNumber}&countryCode=${countryCode}`,
      );

      // End loading
      dispatch(endUserUpdate());

      return true;
    } catch (err) {
      dispatch(setApiError());
      dispatch(endUserUpdate());

      // Return error from the API
      return err as ResponseError;
    }
  };

/**
 * @function fetchUsers
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP GET to request a user
 *
 * @param phoneNumber
 * @param countryCode
 */
export const fetchUsers =
  (
    {
      search,
    }: {
      search: string;
    },
    requestFn = requestRetry,
  ) =>
  async (dispatch: Dispatch): Promise<any | ResponseError> => {
    try {
      // dispatch action that sets "loading: true"
      dispatch(startUserUpdate());

      // Search for users
      const response = await requestFn(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.FETCH_USERS}?search=${search}`,
      );

      // Dispatch Redux Action to save the user to Redux Store
      dispatch(saveSearchUsers(response));

      // Remove loading
      dispatch(endUserUpdate());

      // return results of the request
      return response;
    } catch (e) {
      dispatch(setApiError());
      dispatch(endUserUpdate());

      // return the error from the API
      return e as ResponseError;
    }
  };
