import { IUser, IPhoneInfo, ICompanyRole, IUserData } from '../types/user';
import { errorCodeMap } from '../constants';
import isEmpty from 'lodash/isEmpty';
import { IResponseValidationInnerError, IError } from '../types/errors';
import { ISafiriAPiErrorMap } from '../constants/user';
import { ICompany } from '../state/company/types';
import { USER_PERMISSION } from '../constants/permissions';

export const defaultUserState: IUser = {
  _id: '',
  email: '',
  fullName: '',
  phoneNumber: '',
  countryCode: '',
  countryCodeName: '',
  picture: '',
  uuid: '',
  permissionLevel: 1,
  isEmailConfirmed: false,
  isPhoneNumberConfirmed: false,
  pushNotifications: true,
  subscribedToSales: true,
  // joinedDate: '2020-08-02T13:11:44.043Z', // set default date which wasn't present initially
};

export function mapApiErrors(response: any, mapObject: ISafiriAPiErrorMap) {
  // if api error is a validation error, the error objects will be in an array
  // drill through the error arrays to change the name

  if (
    response.errorCode === errorCodeMap.VALIDATION_ERROR &&
    Array.isArray(response.error) &&
    !isEmpty(response.error)
  ) {
    response.error.forEach(
      (errorObj: IResponseValidationInnerError | IError) => {
        errorObj.property = mapObject[errorObj.property] || errorObj.property;
      },
    );
  }

  // change api error.name from to userPhone for the phone-input component
  // if this is not a validation error
  if (
    response &&
    !Array.isArray(response.error) &&
    !isEmpty(response.error) &&
    response.error.name
  ) {
    response.error.name = mapObject[response.error.name] || response.error.name;
  }
}

export function getPhoneNumber(phoneNumber: string): IPhoneInfo {
  const phoneDetails: IPhoneInfo = {
    countryCode: '',
    phoneNumber: '',
    countryCodeName: '',
  };

  if (isEmpty(phoneNumber)) return { phoneNumber: '', countryCode: '' };

  // split phone number
  const [code, ...phone] = phoneNumber.split(' ');

  // get the phone number
  phoneDetails.phoneNumber = phone.join('');

  // country code
  const codeArray = code.split('');
  phoneDetails.countryCode = codeArray.slice(2, codeArray.length - 1).join('');

  return phoneDetails;
}

export function getUserRole(user?: IUser, company?: ICompany): number {
  if (user?.permissionLevel === USER_PERMISSION.SUDO)
    return USER_PERMISSION.SUDO;

  if (!user) return USER_PERMISSION.UNVERIFIED;

  if (!company) return USER_PERMISSION.UNVERIFIED;

  const tinNumber = company?.tinNumber ?? '';
  const companies = user?.companies ?? {};

  const userCompany = companies?.[tinNumber] || ({} as ICompanyRole);

  if (isEmpty(userCompany)) return USER_PERMISSION.UNVERIFIED;

  return userCompany.role;
}

export function safiriPrivilegedUsers(user?: IUser): boolean {
  const userPermission = +(user?.permissionLevel as number);

  if (userPermission >= USER_PERMISSION.ADMIN) return true;

  return false;
}

export function isSafiriSudo(user?: IUser): boolean {
  if (user?.permissionLevel === USER_PERMISSION.SUDO) return true;

  return false;
}

export function isUserPermitted(role: number): boolean {
  const permittedUsers = [USER_PERMISSION.ADMIN, USER_PERMISSION.OWNER];

  return permittedUsers.includes(role);
}

export function canChangeOwnRole(currentUser: IUser, selectedUser: IUserData) {
  return currentUser._id !== selectedUser.id;
}
