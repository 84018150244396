import { config } from '../config';

export const errorCodeMap = {
  // ---------------- GLOBAL ERROR ---------------------- //
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  BAD_REQUEST: 'BAD_REQUEST',
  NO_INTERNET_CONNECTION: 'NO_INTERNET_CONNECTION',
  ECONNREFUSED: 'ECONNREFUSED',

  // ---------------- LOGON & REGISTER FORMS ---------------------- //
  AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
  EMAIL_DOESNT_EXIST: 'EMAIL_DOESNT_EXIST',
  USER_REVOKED: 'USER_REVOKED',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
  USER_SUSPENDED: 'USER_SUSPENDED',
  USER_DEACTIVATED: 'USER_DEACTIVATED',
  PHONE_NUMBER_DOESNT_EXIST: 'PHONE_NUMBER_DOESNT_EXIST',

  USER_NOT_LINKED_TO_COMPANY: 'USER_NOT_LINKED_TO_COMPANY',
  USER_LINKED_TO_COMPANY: 'USER_LINKED_TO_COMPANY',

  VEHICLE_DOESNT_EXIST: 'VEHICLE_DOESNT_EXIST',
  COMPANY_DOESNT_EXIST: 'COMPANY_DOESNT_EXIST',
  SERVICE_PLAN_DOES_NOT_EXIST: 'SERVICE_PLAN_DOES_NOT_EXIST',

  SAFIRI_ADMIN_PASSWORD_REQUIRED: 'SAFIRI_ADMIN_PASSWORD_REQUIRED',
  SAFIRI_ADMIN_USER_NAME_REQUIRED: 'SAFIRI_ADMIN_USER_NAME_REQUIRED',
  ADMIN_PASSWORD_REQUIRED: 'ADMIN_PASSWORD_REQUIRED',
  ADMIN_EMAIL_REQUIRED: 'ADMIN_EMAIL_REQUIRED',
  ADMIN_PHONE_NUMBER_REQUIRED: 'ADMIN_PHONE_NUMBER_REQUIRED',
  ADMIN_COUNTRY_CODE_REQUIRED: 'ADMIN_COUNTRY_CODE_REQUIRED',
  COMPANY_PHONE_NUMBER_REQUIRED: 'COMPANY_PHONE_NUMBER_REQUIRED',
  COMPANY_COUNTRY_CODE_REQUIRED: 'COMPANY_COUNTRY_CODE_REQUIRED',
  ADMIN_NAME_REQUIRED: 'ADMIN_NAME_REQUIRED',
  COMPANY_NAME_REQUIRED: 'COMPANY_NAME_REQUIRED',
  COMPANY_SERVICE_PLAN_REQUIRED: 'COMPANY_SERVICE_PLAN_REQUIRED',
  COMPANY_TRADING_NAME_REQUIRED: 'COMPANY_TRADING_NAME_REQUIRED',
  CODE_REQUIRED: 'CODE_REQUIRED',
  TIN_NUMBER_REQUIRED: 'TIN_NUMBER_REQUIRED',
  PHONE_NUMBER_REQUIRED: 'PHONE_NUMBER_REQUIRED',
  COUNTRY_CODE_REQUIRED: 'COUNTRY_CODE_REQUIRED',
  COUNTRY_CODE_NAME_REQUIRED: 'COUNTRY_CODE_NAME_REQUIRED',
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',
  COMPANY_EMAIL_REQUIRED: 'COMPANY_EMAIL_REQUIRED',

  PASSWORD_VALIDATION_ERROR: 'PASSWORD_VALIDATION_ERROR',
  ADMIN_PASSWORD_VALIDATION_ERROR: 'ADMIN_PASSWORD_VALIDATION_ERROR',
  SAFIRI_ADMIN_PASSWORD_VALIDATION_ERROR:
    'SAFIRI_ADMIN_PASSWORD_VALIDATION_ERROR',
  PHONE_NUMBER_VALIDATION_ERROR: 'PHONE_NUMBER_VALIDATION_ERROR',
  EMAIL_VALIDATION_ERROR: 'EMAIL_VALIDATION_ERROR',
  FULL_NAME_VALIDATION_ERROR: 'FULL_NAME_VALIDATION_ERROR',
  COUNTRY_CODE_NAME_INVALID: 'COUNTRY_CODE_NAME_INVALID',
  TIN_NUMBER_INVALID: 'TIN_NUMBER_INVALID',

  FAILED_REGISTERING_USER: 'FAILED_REGISTERING_USER', // MODAL ERRORS

  VALIDATION_ERROR: 'VALIDATION_ERROR',

  EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
  MOBILE_ALREADY_EXISTS: 'MOBILE_ALREADY_EXISTS',
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  COMPANY_NAME_ALREADY_EXISTS: 'COMPANY_NAME_ALREADY_EXISTS',
  TIN_NUMBER_ALREADY_EXISTS: 'TIN_NUMBER_ALREADY_EXISTS',
  COMPANY_EMAIL_ALREADY_EXISTS: 'COMPANY_EMAIL_ALREADY_EXISTS',
  COMPANY_MOBILE_ALREADY_EXISTS: 'COMPANY_MOBILE_ALREADY_EXISTS',
  PLATE_NUMBER_ALREADY_EXISTS: 'PLATE_NUMBER_ALREADY_EXISTS',

  RESET_PASSWORD_CODE_MISMATCH: 'RESET_PASSWORD_CODE_MISMATCH',
  NO_PASSWORD_RESET_CODE: 'NO_PASSWORD_RESET_CODE',

  ADMIN_PHONE_NUMBER_MINIMUM_LENGTH: 'ADMIN_PHONE_NUMBER_MINIMUM_LENGTH',
  ADMIN_PHONE_NUMBER_MAXIMUM_LENGTH: 'ADMIN_PHONE_NUMBER_MAXIMUM_LENGTH',
  ADMIN_COUNTRY_CODE_MINIMUM_LENGTH: 'ADMIN_COUNTRY_CODE_MINIMUM_LENGTH',
  ADMIN_COUNTRY_CODE_MAXIMUM_LENGTH: 'ADMIN_COUNTRY_CODE_MAXIMUM_LENGTH',
  COMPANY_PHONE_NUMBER_MINIMUM_LENGTH: 'COMPANY_PHONE_NUMBER_MINIMUM_LENGTH',
  COMPANY_PHONE_NUMBER_MAXIMUM_LENGTH: 'COMPANY_PHONE_NUMBER_MAXIMUM_LENGTH',
  COMPANY_COUNTRY_CODE_MINIMUM_LENGTH: 'COMPANY_COUNTRY_CODE_MINIMUM_LENGTH',
  COMPANY_COUNTRY_CODE_MAXIMUM_LENGTH: 'COMPANY_COUNTRY_CODE_MAXIMUM_LENGTH',
  COUNTRY_CODE_NAME_MAXIMUM_LENGTH: 'COUNTRY_CODE_NAME_MAXIMUM_LENGTH',
  COUNTRY_CODE_NAME_MINIMUM_LENGTH: 'COUNTRY_CODE_NAME_MINIMUM_LENGTH',
  PHONE_NUMBER_MAXIMUM_LENGTH: 'PHONE_NUMBER_MAXIMUM_LENGTH',
  PHONE_NUMBER_MINIMUM_LENGTH: 'PHONE_NUMBER_MINIMUM_LENGTH',
  COUNTRY_CODE_MAXIMUM_LENGTH: 'COUNTRY_CODE_MAXIMUM_LENGTH',
  COUNTRY_CODE_MINIMUM_LENGTH: 'COUNTRY_CODE_MINIMUM_LENGTH',
  MINIMUM_SEATS: 'MINIMUM_SEATS',
  COMPANY_NAME_MINIMUM_LENGTH: 'COMPANY_NAME_MINIMUM_LENGTH',
  COMPANY_TRADING_NAME_MINIMUM_LENGTH: 'COMPANY_TRADING_NAME_MINIMUM_LENGTH',

  REFRESH_TOKEN_VALIDATION_ERROR: 'REFRESH_TOKEN_VALIDATION_ERROR',
  TOKEN_TIMEOUT: 'TOKEN_TIMEOUT',
  REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',

  NO_TIN_NUMBER_IN_PATH: 'NO_TIN_NUMBER_IN_PATH',
  NO_COMPANY_VEHICLES_ADDED: 'NO_COMPANY_VEHICLES_ADDED',

  NO_TICKETS: 'NO_TICKETS',

  EMAIL_TOKEN_MISMATCH: 'EMAIL_TOKEN_MISMATCH',
  NO_EMAIL_TOKEN: 'NO_EMAIL_TOKEN',

  MESSAGE_NOT_SENT: 'MESSAGE_NOT_SENT',

  PHONE_NUMBER_TOKEN_MISMATCH: 'PHONE_NUMBER_TOKEN_MISMATCH',
  NO_PHONE_NUMBER_TOKEN: 'NO_PHONE_NUMBER_TOKEN',

  INVALID_MONGO_ID: 'INVALID_MONGO_ID',
  EDIT_PROFILE_NO_CHANGE: 'EDIT_PROFILE_NO_CHANGE',

  INVALID_COMPANY_TYPE: 'INVALID_COMPANY_TYPE',

  INVALID_URL: 'INVALID_URL',

  DISCOUNT_DOES_NOT_EXIST: 'DISCOUNT_DOES_NOT_EXIST',
  DISCOUNT_CODE_HAS_EXPIRED: 'DISCOUNT_CODE_HAS_EXPIRED',
  DISCOUNT_CODE_IS_INVALID: 'DISCOUNT_CODE_IS_INVALID',
  DISCOUNT_RATE_OR_AMOUNT_REQUIRED: 'DISCOUNT_RATE_OR_AMOUNT_REQUIRED',
  DISCOUNT_CODE_REQUIRED: 'DISCOUNT_CODE_REQUIRED',
  DISCOUNT_NAME_REQUIRED: 'DISCOUNT_NAME_REQUIRED',
  DISCOUNT_MAXIMUM_LENGTH: 'DISCOUNT_MAXIMUM_LENGTH',
  DISCOUNT_MINIMUM_LENGTH: 'DISCOUNT_MINIMUM_LENGTH',
  DISCOUNT_END_DATE_REQUIRED: 'DISCOUNT_END_DATE_REQUIRED',
  DISCOUNT_START_DATE_REQUIRED: 'DISCOUNT_START_DATE_REQUIRED',
  DISCOUNT_CODE_ALREADY_EXISTS: 'DISCOUNT_CODE_ALREADY_EXISTS',

  ACCOUNT_NAME_REQUIRED: 'ACCOUNT_NAME_REQUIRED',
  ACCOUNT_NUMBER_REQUIRED: 'ACCOUNT_NUMBER_REQUIRED',
  ACCOUNT_ADDRESS_REQUIRED: 'ACCOUNT_ADDRESS_REQUIRED',
  BANK_NAME_REQUIRED: 'BANK_NAME_REQUIRED',
  BANK_ID_REQUIRED: 'BANK_ID_REQUIRED',
  BANK_SWIFTCODE_REQUIRED: 'BANK_SWIFTCODE_REQUIRED',

  INVALID_PERMISSION_NOT_A_SAFIRI_ADMIN_OR_SUDO:
    'INVALID_PERMISSION_NOT_A_SAFIRI_ADMIN_OR_SUDO',
  INVALID_SERVICE_PLAN_TYPE: 'INVALID_SERVICE_PLAN_TYPE',

  PLAN_DOES_NOT_EXIST: 'PLAN_DOES_NOT_EXIST',
  PLAN_NAME_REQUIRED: 'PLAN_NAME_REQUIRED',
  PLAN_RATE_REQUIRED: 'PLAN_RATE_REQUIRED',
  PLAN_FEATURES_REQUIRED: 'PLAN_FEATURES_REQUIRED',
  PLAN_NAME_MINIMUM_LENGTH: 'PLAN_NAME_MINIMUM_LENGTH',
  PLAN_NAME_ALREADY_EXISTS: 'PLAN_NAME_ALREADY_EXISTS',
  FAILED_REGISTERING_PLAN: 'FAILED_REGISTERING_PLAN',
  INVALID_PLAN_RATE: 'INVALID_PLAN_RATE',

  FREE_TRIAL_ACTIVE_FIELD_REQUIRED: 'FREE_TRIAL_ACTIVE_FIELD_REQUIRED',
  FREE_TRIAL_START_DATE_REQUIRED: 'FREE_TRIAL_START_DATE_REQUIRED',
  FREE_TRIAL_END_DATE_REQUIRED: 'FREE_TRIAL_END_DATE_REQUIRED',
  FREE_TRIAL_DURATION_REQUIRED: 'FREE_TRIAL_DURATION_REQUIRED',
  FREE_TRIAL_ACTIVATED_BY_REQUIRED: 'FREE_TRIAL_ACTIVATED_BY_REQUIRED',

  FREE_TRIAL_INVALID_START_DATE: 'FREE_TRIAL_INVALID_START_DATE',
  FREE_TRIAL_INVALID_END_DATE: 'FREE_TRIAL_INVALID_END_DATE',

  FREE_TRIAL_INVALID_TRIAL_ACTIVE_VALUE:
    'FREE_TRIAL_INVALID_TRIAL_ACTIVE_VALUE',

  FREE_TRIAL_INVALID_DURATION: 'FREE_TRIAL_INVALID_DURATION',

  SETTING_DEFAULT_TOTAL_SEATS_VALIDATION_ERROR:
    'SETTING_DEFAULT_TOTAL_SEATS_VALIDATION_ERROR',

  SETTING_RECEIVE_DAILY_SALES_VALIDATION_ERROR:
    'SETTING_RECEIVE_DAILY_SALES_VALIDATION_ERROR',
  CANNOT_TURN_ON_DAILY_SALES_REPORTS: 'CANNOT_TURN_ON_DAILY_SALES_REPORTS',

  FAILED_TO_SEND_PUSH_NOTIFICATION: 'FAILED_TO_SEND_PUSH_NOTIFICATION',
};

export interface FormError {
  errorAria: string;
  errorText: string;
}

export interface FormErrors {
  [key: string]: FormError;
}

export const createFormErrors = (
  translation: (key: string, config?: any) => string,
): FormErrors => {
  return {
    // ---------------- GLOBAL ---------------------- //
    [errorCodeMap.NOT_FOUND]: {
      errorAria: translation(`global.formValidation.notFound.aria`),
      errorText: translation(`global.formValidation.notFound.text`),
    },
    [errorCodeMap.BAD_REQUEST]: {
      errorAria: translation(`global.formValidation.badRequest.aria`),
      errorText: translation(`global.formValidation.badRequest.text`),
    },
    [errorCodeMap.INTERNAL_SERVER_ERROR]: {
      errorAria: translation(`global.formValidation.internalServerError.aria`),
      errorText: translation(`global.formValidation.internalServerError.text`),
    },

    // ---------------- LOGON FORM ---------------------- //
    [errorCodeMap.ADMIN_EMAIL_REQUIRED]: {
      errorAria: translation('logon.formValidation.email.empty.aria'),
      errorText: translation('logon.formValidation.email.empty.text'),
    },
    [errorCodeMap.EMAIL_DOESNT_EXIST]: {
      errorAria: translation('logon.formValidation.email.invalid.aria'),
      errorText: translation('logon.formValidation.email.invalid.text'),
    },
    [errorCodeMap.PHONE_NUMBER_DOESNT_EXIST]: {
      errorAria: translation('logon.formValidation.phoneNumber.exists.aria'),
      errorText: translation('logon.formValidation.phoneNumber.exists.text'),
    },
    [errorCodeMap.ADMIN_PASSWORD_REQUIRED]: {
      errorAria: translation('logon.formValidation.password.empty.aria'),
      errorText: translation('logon.formValidation.password.empty.text'),
    },
    [errorCodeMap.ADMIN_PASSWORD_VALIDATION_ERROR]: {
      errorAria: translation('register.formValidation.password.minLength.aria'),
      errorText: translation('register.formValidation.password.minLength.text'),
    },
    [errorCodeMap.WRONG_PASSWORD]: {
      errorAria: translation('logon.formValidation.password.invalid.aria'),
      errorText: translation('logon.formValidation.password.invalid.text'),
    },
    [errorCodeMap.PASSWORD_VALIDATION_ERROR]: {
      errorAria: translation('logon.formValidation.password.minLength.aria'),
      errorText: translation('logon.formValidation.password.minLength.text'),
    },
    [errorCodeMap.USER_NOT_LINKED_TO_COMPANY]: {
      errorAria: translation(
        'logon.formValidation.modal.noCompanyLinked.aria',
        {
          appName: config.REACT_APP_COMPANY_NAME,
        },
      ),
      errorText: translation(
        'logon.formValidation.modal.noCompanyLinked.text',
        {
          appName: config.REACT_APP_COMPANY_NAME,
        },
      ),
    },
    [errorCodeMap.USER_LINKED_TO_COMPANY]: {
      errorAria: translation('logon.formValidation.modal.companyLinked.aria', {
        appName: config.REACT_APP_COMPANY_NAME,
      }),
      errorText: translation('logon.formValidation.modal.companyLinked.text', {
        appName: config.REACT_APP_COMPANY_NAME,
      }),
    },

    // ---------------- REGISTER FORM ---------------------- //

    [errorCodeMap.COMPANY_NAME_ALREADY_EXISTS]: {
      errorAria: translation(
        'register.formValidation.company.name.exists.aria',
      ),
      errorText: translation(
        'register.formValidation.company.name.exists.aria',
      ),
    },
    [errorCodeMap.COMPANY_EMAIL_REQUIRED]: {
      errorAria: translation(
        'register.formValidation.company.email.empty.aria',
      ),
      errorText: translation(
        'register.formValidation.company.email.empty.text',
      ),
    },
    [errorCodeMap.COMPANY_PHONE_NUMBER_REQUIRED]: {
      errorAria: translation('register.formValidation.phoneNumber.empty.aria'),
      errorText: translation('register.formValidation.phoneNumber.empty.text'),
    },
    [errorCodeMap.ADMIN_PHONE_NUMBER_REQUIRED]: {
      errorAria: translation('register.formValidation.phoneNumber.empty.aria'),
      errorText: translation('register.formValidation.phoneNumber.empty.text'),
    },
    [errorCodeMap.COMPANY_COUNTRY_CODE_REQUIRED]: {
      errorAria: translation('register.formValidation.countryCode.empty.aria'),
      errorText: translation('register.formValidation.countryCode.empty.text'),
    },
    [errorCodeMap.INVALID_COMPANY_TYPE]: {
      errorAria: translation('register.formValidation.type.aria'),
      errorText: translation('register.formValidation.type.text'),
    },
    [errorCodeMap.INVALID_SERVICE_PLAN_TYPE]: {
      errorAria: translation('register.formValidation.servicePlan.empty.aria'),
      errorText: translation('register.formValidation.servicePlan.empty.text'),
    },
    [errorCodeMap.ADMIN_COUNTRY_CODE_REQUIRED]: {
      errorAria: translation('register.formValidation.countryCode.empty.aria'),
      errorText: translation('register.formValidation.countryCode.empty.text'),
    },
    [errorCodeMap.PHONE_NUMBER_VALIDATION_ERROR]: {
      errorAria: translation(
        'register.formValidation.phoneNumber.invalid.aria',
      ),
      errorText: translation(
        'register.formValidation.phoneNumber.invalid.text',
      ),
    },
    [errorCodeMap.COMPANY_PHONE_NUMBER_MINIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.phoneNumber.minLength.aria',
      ),
      errorText: translation(
        'register.formValidation.phoneNumber.minLength.text',
      ),
    },
    [errorCodeMap.ADMIN_PHONE_NUMBER_MINIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.phoneNumber.minLength.aria',
      ),
      errorText: translation(
        'register.formValidation.phoneNumber.minLength.text',
      ),
    },
    [errorCodeMap.COMPANY_PHONE_NUMBER_MAXIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.phoneNumber.maxLength.aria',
      ),
      errorText: translation(
        'register.formValidation.phoneNumber.maxLength.text',
      ),
    },
    [errorCodeMap.ADMIN_PHONE_NUMBER_MAXIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.phoneNumber.maxLength.aria',
      ),
      errorText: translation(
        'register.formValidation.phoneNumber.maxLength.text',
      ),
    },
    [errorCodeMap.ADMIN_NAME_REQUIRED]: {
      errorAria: translation('register.formValidation.fullName.empty.aria'),
      errorText: translation('register.formValidation.fullName.empty.text'),
    },
    [errorCodeMap.COMPANY_NAME_REQUIRED]: {
      errorAria: translation('register.formValidation.company.name.empty.aria'),
      errorText: translation('register.formValidation.company.name.empty.text'),
    },
    [errorCodeMap.COMPANY_SERVICE_PLAN_REQUIRED]: {
      errorAria: translation(
        'register.formValidation.company.servicePlan.empty.aria',
      ),
      errorText: translation(
        'register.formValidation.company.servicePlan.empty.text',
      ),
    },
    [errorCodeMap.SERVICE_PLAN_DOES_NOT_EXIST]: {
      errorAria: translation(
        'register.formValidation.company.servicePlan.exists.aria',
      ),
      errorText: translation(
        'register.formValidation.company.servicePlan.exists.text',
      ),
    },
    [errorCodeMap.COMPANY_NAME_MINIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.company.name.minLength.aria',
      ),
      errorText: translation(
        'register.formValidation.company.name.minLength.text',
      ),
    },
    [errorCodeMap.COMPANY_TRADING_NAME_REQUIRED]: {
      errorAria: translation(
        'register.formValidation.company.tradingName.empty.aria',
      ),
      errorText: translation(
        'register.formValidation.company.tradingName.empty.text',
      ),
    },
    [errorCodeMap.COMPANY_TRADING_NAME_MINIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.company.tradingName.minLength.aria',
      ),
      errorText: translation(
        'register.formValidation.company.tradingName.minLength.text',
      ),
    },
    [errorCodeMap.FULL_NAME_VALIDATION_ERROR]: {
      errorAria: translation('register.formValidation.fullName.minLength.aria'),
      errorText: translation('register.formValidation.fullName.minLength.text'),
    },
    [errorCodeMap.PASSWORD_VALIDATION_ERROR]: {
      errorAria: translation('register.formValidation.password.minLength.aria'),
      errorText: translation('register.formValidation.password.minLength.text'),
    },
    [errorCodeMap.EMAIL_VALIDATION_ERROR]: {
      errorAria: translation('register.formValidation.email.invalid.aria'),
      errorText: translation('register.formValidation.email.invalid.text'),
    },
    [errorCodeMap.EMAIL_ALREADY_EXISTS]: {
      errorAria: translation('register.formValidation.email.exists.aria'),
      errorText: translation('register.formValidation.email.exists.text'),
    },
    [errorCodeMap.MOBILE_ALREADY_EXISTS]: {
      errorAria: translation('register.formValidation.phoneNumber.exists.aria'),
      errorText: translation('register.formValidation.phoneNumber.exists.text'),
    },
    [errorCodeMap.TIN_NUMBER_REQUIRED]: {
      errorAria: translation('register.formValidation.tinNumber.empty.aria'),
      errorText: translation('register.formValidation.tinNumber.empty.text'),
    },
    [errorCodeMap.TIN_NUMBER_INVALID]: {
      errorAria: translation('register.formValidation.tinNumber.invalid.aria'),
      errorText: translation('register.formValidation.tinNumber.invalid.text'),
    },
    [errorCodeMap.TIN_NUMBER_ALREADY_EXISTS]: {
      errorAria: translation('register.formValidation.tinNumber.exists.aria'),
      errorText: translation('register.formValidation.tinNumber.exists.text'),
    },
    [errorCodeMap.COMPANY_EMAIL_ALREADY_EXISTS]: {
      errorAria: translation('register.formValidation.email.exists.aria'),
      errorText: translation('register.formValidation.email.exists.text'),
    },
    [errorCodeMap.COMPANY_MOBILE_ALREADY_EXISTS]: {
      errorAria: translation('register.formValidation.phoneNumber.exists.aria'),
      errorText: translation('register.formValidation.phoneNumber.exists.text'),
    },
    [errorCodeMap.SAFIRI_ADMIN_PASSWORD_REQUIRED]: {
      errorAria: translation('register.safiri.admin.password.empty.aria', {
        appName: config.REACT_APP_COMPANY_NAME,
      }),
      errorText: translation('register.safiri.admin.password.empty.text', {
        appName: config.REACT_APP_COMPANY_NAME,
      }),
    },
    [errorCodeMap.SAFIRI_ADMIN_PASSWORD_VALIDATION_ERROR]: {
      errorAria: translation('register.formValidation.password.minLength.aria'),
      errorText: translation('register.formValidation.password.minLength.text'),
    },
    [errorCodeMap.INVALID_URL]: {
      errorAria: translation('register.formValidation.website.invalid.aria'),
      errorText: translation('register.formValidation.website.invalid.text'),
    },
    [errorCodeMap.INVALID_PERMISSION_NOT_A_SAFIRI_ADMIN_OR_SUDO]: {
      errorAria: translation(
        'register.formValidation.insufficientRightToOpenCompany',
        {
          appName: config.REACT_APP_COMPANY_NAME,
        },
      ),
      errorText: translation(
        'register.formValidation.insufficientRightToOpenCompany',
        {
          appName: config.REACT_APP_COMPANY_NAME,
        },
      ),
    },

    // ---------------- VEHICLE FORM ----------------------- //
    [errorCodeMap.PLATE_NUMBER_ALREADY_EXISTS]: {
      errorAria: translation('vehicles.formValidation.plateNumber.exists.aria'),
      errorText: translation('vehicles.formValidation.plateNumber.exists.aria'),
    },
    [errorCodeMap.MINIMUM_SEATS]: {
      errorAria: translation('vehicles.formValidation.seats.minimum.text'),
      errorText: translation('vehicles.formValidation.seats.minimum.text'),
    },
    [errorCodeMap.NO_TIN_NUMBER_IN_PATH]: {
      errorAria: translation('vehicles.all.search.by.tinNumber'),
      errorText: translation('vehicles.all.search.by.tinNumber'),
    },
    [errorCodeMap.NO_COMPANY_VEHICLES_ADDED]: {
      errorAria: translation('vehicles.all.empty'),
      errorText: translation('vehicles.all.empty'),
    },

    // ________________ ADD USER FORM _____________________ //
    [errorCodeMap.EMAIL_REQUIRED]: {
      errorAria: translation('staff.email.empty.text'),
      errorText: translation('staff.email.empty.text'),
    },
    [errorCodeMap.PASSWORD_REQUIRED]: {
      errorAria: translation('register.formValidation.password.empty.text'),
      errorText: translation('register.formValidation.password.empty.text'),
    },
    [errorCodeMap.PHONE_NUMBER_REQUIRED]: {
      errorAria: translation('register.formValidation.phoneNumber.empty.aria'),
      errorText: translation('register.formValidation.phoneNumber.empty.text'),
    },
    [errorCodeMap.PHONE_NUMBER_MAXIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.phoneNumber.maxLength.aria',
      ),
      errorText: translation(
        'register.formValidation.phoneNumber.maxLength.text',
      ),
    },
    [errorCodeMap.PHONE_NUMBER_MINIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.phoneNumber.minLength.aria',
      ),
      errorText: translation(
        'register.formValidation.phoneNumber.minLength.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_REQUIRED]: {
      errorAria: translation('register.formValidation.countryCode.empty.aria'),
      errorText: translation('register.formValidation.countryCode.empty.text'),
    },
    [errorCodeMap.COUNTRY_CODE_MAXIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.countryCode.maxLength.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCode.maxLength.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_MINIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.countryCode.minLength.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCode.minLength.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_NAME_MAXIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.countryCodeName.maxLength.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCodeName.maxLength.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_NAME_MINIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.countryCodeName.minLength.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCodeName.minLength.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_NAME_REQUIRED]: {
      errorAria: translation(
        'register.formValidation.countryCodeName.empty.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCodeName.empty.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_NAME_INVALID]: {
      errorAria: translation(
        'register.formValidation.countryCodeName.empty.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCodeName.empty.text',
      ),
    },

    // ---------------- PASSWORD FORM ---------------------- //
    [errorCodeMap.CODE_REQUIRED]: {
      errorAria: translation('password.formValidation.code.empty.aria'),
      errorText: translation('password.formValidation.code.empty.text'),
    },
    [errorCodeMap.RESET_PASSWORD_CODE_MISMATCH]: {
      errorAria: translation('password.formValidation.code.invalid.aria'),
      errorText: translation('password.formValidation.code.invalid.text'),
    },
    [errorCodeMap.NO_PASSWORD_RESET_CODE]: {
      errorAria: translation('password.formValidation.code.noCode.aria'),
      errorText: translation('password.formValidation.code.noCode.text'),
    },

    // ---------------- VERIFY EMAIL FORM ---------------------- //
    [errorCodeMap.EMAIL_TOKEN_MISMATCH]: {
      errorAria: translation('verifyEmail.mismatch'),
      errorText: translation('verifyEmail.mismatch'),
    },
    [errorCodeMap.PHONE_NUMBER_TOKEN_MISMATCH]: {
      errorAria: translation('verifyMobile.mismatch'),
      errorText: translation('verifyMobile.mismatch'),
    },

    // --------------- EDIT PROFILE FORM ----------------- //
    [errorCodeMap.EDIT_PROFILE_NO_CHANGE]: {
      errorAria: translation('updateProfile.noUpdate'),
      errorText: translation('updateProfile.noUpdate'),
    },

    // --------------- DISCOUNT FORM ------------------- //
    [errorCodeMap.DISCOUNT_DOES_NOT_EXIST]: {
      errorAria: translation('discount.form.code.notExists'),
      errorText: translation('discount.form.code.notExists'),
    },
    [errorCodeMap.DISCOUNT_CODE_ALREADY_EXISTS]: {
      errorAria: translation('discount.form.code.exists'),
      errorText: translation('discount.form.code.exists'),
    },
    [errorCodeMap.DISCOUNT_MAXIMUM_LENGTH]: {
      errorAria: translation('discount.form.code.length'),
      errorText: translation('discount.form.code.length'),
    },
    [errorCodeMap.DISCOUNT_MINIMUM_LENGTH]: {
      errorAria: translation('discount.form.code.length'),
      errorText: translation('discount.form.code.length'),
    },
    [errorCodeMap.DISCOUNT_CODE_REQUIRED]: {
      errorAria: translation('discount.form.code.required'),
      errorText: translation('discount.form.code.required'),
    },
    [errorCodeMap.DISCOUNT_CODE_IS_INVALID]: {
      errorAria: translation('discount.form.code.notExists'),
      errorText: translation('discount.form.code.notExists'),
    },
    [errorCodeMap.DISCOUNT_RATE_OR_AMOUNT_REQUIRED]: {
      errorAria: translation('discount.form.amount.required'),
      errorText: translation('discount.form.amount.required'),
    },
    [errorCodeMap.DISCOUNT_NAME_REQUIRED]: {
      errorAria: translation('discount.form.name.required'),
      errorText: translation('discount.form.name.required'),
    },

    [errorCodeMap.DISCOUNT_END_DATE_REQUIRED]: {
      errorAria: translation('discount.form.endDate.required'),
      errorText: translation('discount.form.endDate.required'),
    },
    [errorCodeMap.DISCOUNT_START_DATE_REQUIRED]: {
      errorAria: translation('discount.form.startDate.required'),
      errorText: translation('discount.form.startDate.required'),
    },

    // --------------- COMPANY FORM ------------------- //
    [errorCodeMap.ACCOUNT_NAME_REQUIRED]: {
      errorAria: translation('company.bankDetails.accountName.placeholder'),
      errorText: translation('company.bankDetails.accountName.placeholder'),
    },
    [errorCodeMap.ACCOUNT_NUMBER_REQUIRED]: {
      errorAria: translation('company.bankDetails.accountNumber.placeholder'),
      errorText: translation('company.bankDetails.accountNumber.placeholder'),
    },
    [errorCodeMap.ACCOUNT_ADDRESS_REQUIRED]: {
      errorAria: translation('company.bankDetails.address.placeholder'),
      errorText: translation('company.bankDetails.address.placeholder'),
    },
    [errorCodeMap.BANK_ID_REQUIRED]: {
      errorAria: translation('company.bankDetails.bankName.placeholder'),
      errorText: translation('company.bankDetails.bankName.placeholder'),
    },
    [errorCodeMap.BANK_NAME_REQUIRED]: {
      errorAria: translation('company.bankDetails.bankName.placeholder'),
      errorText: translation('company.bankDetails.bankName.placeholder'),
    },
    [errorCodeMap.BANK_SWIFTCODE_REQUIRED]: {
      errorAria: translation('company.bankDetails.swiftCode.placeholder'),
      errorText: translation('company.bankDetails.swiftCode.placeholder'),
    },

    // -------------- PLAN FORM ---------------------- //
    [errorCodeMap.PLAN_NAME_REQUIRED]: {
      errorAria: translation('plan.form.planName.required'),
      errorText: translation('plan.form.planName.required'),
    },
    [errorCodeMap.PLAN_DOES_NOT_EXIST]: {
      errorAria: translation('plan.form.planName.missing'),
      errorText: translation('plan.form.planName.missing'),
    },
    [errorCodeMap.PLAN_NAME_ALREADY_EXISTS]: {
      errorAria: translation('plan.form.planName.exists'),
      errorText: translation('plan.form.planName.exists'),
    },
    [errorCodeMap.PLAN_NAME_MINIMUM_LENGTH]: {
      errorAria: translation('plan.form.planName.length'),
      errorText: translation('plan.form.planName.length'),
    },
    [errorCodeMap.PLAN_RATE_REQUIRED]: {
      errorAria: translation('plan.form.planRate.required'),
      errorText: translation('plan.form.planRate.required'),
    },
    [errorCodeMap.INVALID_PLAN_RATE]: {
      errorAria: translation('plan.form.planRate.invalid'),
      errorText: translation('plan.form.planRate.invalid'),
    },
    [errorCodeMap.PLAN_FEATURES_REQUIRED]: {
      errorAria: translation('plan.form.features.select.placeholder'),
      errorText: translation('plan.form.features.select.placeholder'),
    },
    [errorCodeMap.FAILED_REGISTERING_PLAN]: {
      errorAria: translation('global.formValidation.badRequest.aria'),
      errorText: translation('global.formValidation.badRequest.text'),
    },

    // ------------ SETTING FROM ---------------------- //
    [errorCodeMap.SETTING_DEFAULT_TOTAL_SEATS_VALIDATION_ERROR]: {
      errorAria: translation('settings.company.vehicle.seats.invalid'),
      errorText: translation('settings.company.vehicle.seats.invalid'),
    },
    [errorCodeMap.SETTING_RECEIVE_DAILY_SALES_VALIDATION_ERROR]: {
      errorAria: translation(`global.formValidation.badRequest.aria`),
      errorText: translation(`global.formValidation.badRequest.text`),
    },
    [errorCodeMap.CANNOT_TURN_ON_DAILY_SALES_REPORTS]: {
      errorAria: translation(
        `settings.general.receivesDailySalesReport.invalidPlan`,
      ),
      errorText: translation(
        `settings.general.receivesDailySalesReport.invalidPlan`,
      ),
    },
  };
};

export const minimumPasswordCharactersLength = 6;
export const minimumFullNameCharactersLength = 2;
export const safiriCommissionRate = '10%';

export const commissionRateShort = 'Short';
export const commissionRateLong = 'Long';

export const sensitiveprimaryCodesMap = {
  TOKENS: 'TOKENS',
  USER_ID: 'USER_ID',
  LANGUAGE: 'LANGUAGE',
};

export const sensitiveprimaryDefaultOptions = {
  sharedPreferencesName: 'mySharedPrefs',
  keychainService: 'myKeychain',
};

export const BACK_NAVIGATION_BUTTON = 'GO_BACK_NAVIGATION_BUTTON';
export const FILTER_NAVIGATION_BUTTON = 'FILTER_NAVIGATION_BUTTON';
export const I18N_FIRST_INDEX = '{0}';
export const DEPARTING_FROM = 'DEPARTING_FROM';
export const DESTINATION = 'DESTINATION';

// ---------------- ACCOUNT TERMINATION PERIOD LIMIT ---------------------- //
export const EMAIL_VERIFICATION_MAX_DAYS = 14;
export const EMAIL_VERIFICATION_FIRST_WARNING = 10;
export const EMAIL_VERIFICATION_SECOND_WARNING = 6;
export const EMAIL_VERIFICATION_FINAL_WARNING = 3;

// ----------------- LANGUAGES ------------------------------------------- //
export const ENGLISH = 'en';
export const SWAHILI = 'sw';

// ---------------- HTTP METHODS ----------------------------------------- //
export const httpMethod = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
};

// ________________ STOPS FEATURES -------------------------------------- //
interface IStopFeatures {
  [x: string]: string;
}
export const stopFeature: IStopFeatures = {
  DEPARTURES: 'DEPARTURES',
  GET_ME_HERE: 'GET ME HERE',
  TRACK_BUSES: 'TRACK BUSES',
};

export enum STATION_TYPE {
  PICK_UP = 'pickUpStations',
  DROP_OFF = 'dropOffStations',
}
