import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import isEmpty from 'lodash/isEmpty';

import * as actions from './action-types';

import requestRetry, {
  ResponseError as IResponseError,
  ResponseError,
} from '../../lib/request';
import * as routes from '../../constants/routes';
import { errorCodeMap } from './../../constants/index';

// TypeScript Types
import { ILogonResponse } from '../../types/api';

import { config } from '../../config';
import { saveUser, saveToken, saveRefreshToken } from '../user/actions';
import { ILogon } from '../hook-form/types';
import { IQueryOptions, ICompany } from '../company/types';
import { findCompany, findFirstRandomCompany } from '../company/actions';
import { clearRHFLogonDetails } from '../hook-form/actions';
import { IState } from '../../types/state';
import { safiriPrivilegedUsers } from '../../utils/user';

const { REACT_APP_SAFIRI_BOOKING_API_URL } = config;

export const setApiError = createAction(actions.SET_API_ERROR);
export const clearApiError = createAction(actions.CLEAR_API_ERROR);

export const startAttemptLogon = createAction(actions.START_ATTEMPT_LOGON);
export const endAttemptLogon = createAction(actions.END_ATTEMPT_LOGON);

export const setLoggedIn = createAction(actions.SET_LOGGED_IN);

export const savePhoneNumber = createAction(actions.SAVE_PHONE_NUMBER);

// export const

/**
 * @function logon
 * @description A function that returns a redux asynchronous action (Redux Thunk)
 * This async redux action will inturn send a HTTP POST so that it can validate a users email and password
 *
 * @param ILogon - Logon values. e.g. countryCode, phoneNumber, password
 * @param companyTinNumber - The specific company tin number you want to login to
 */
export const logon =
  ({ countryCode, phoneNumber, password }: ILogon, companyTinNumber?: string) =>
  async (
    dispatch: Dispatch,
    getState: () => IState,
  ): Promise<
    { user: ILogonResponse; company: ICompany } | { user: IResponseError }
  > => {
    try {
      // Dispatch action to set "loading: true"
      dispatch(startAttemptLogon());

      // clear apiError upon success
      dispatch(clearApiError());

      // Authenticate User
      const response = await requestRetry<ILogonResponse>(
        `${REACT_APP_SAFIRI_BOOKING_API_URL}${routes.LOGON}`,
        {
          method: 'POST',
          // Fetch API wants you to stringify the POST object
          body: JSON.stringify({
            countryCode,
            phoneNumber,
            password,
          }),
        },
      );

      // Token is needed to make requests to the client admin api
      await dispatch(saveToken(response.token) as any);

      const companies = response.user?.companies;

      // If user has no company to their profile and is not Safiri Staff then error out
      if (isEmpty(companies) && !safiriPrivilegedUsers(response.user))
        throw new ResponseError({
          code: errorCodeMap.USER_NOT_LINKED_TO_COMPANY,
          errorCode: errorCodeMap.USER_NOT_LINKED_TO_COMPANY,
          error: {
            name: 'phoneNumber',
          },
          message: `This user is not linked to any company.`,
          status: 401,
        });

      // If user has no company to their profile and is a Safiri Staff; get all companies and fetch the first one
      if (isEmpty(companies) && safiriPrivilegedUsers(response.user))
        // Dispatch Redux action to fetch a random company and save it to redux store
        await dispatch(findFirstRandomCompany() as any);

      // If user is registered to a company, fetch the first one
      if (!isEmpty(companies)) {
        const values: IQueryOptions = {
          tinNumber: Object.keys(companies)[0],
        };

        if (companyTinNumber) values.tinNumber = companyTinNumber;

        // Dispatch Redux action to fetch the user's company and save it to redux store
        await dispatch(findCompany(values) as any);
      }

      // Check state if there's no companies on the redux store then throw an error
      const company = await (getState() as IState).company.details;

      if (!company)
        throw new ResponseError({
          code: errorCodeMap.USER_LINKED_TO_COMPANY,
          errorCode: errorCodeMap.USER_LINKED_TO_COMPANY,
          error: {
            name: 'phoneNumber',
          },
          message: `This user is linked to a company but we still failed to log you in.`,
          status: 401,
        });

      // Dispatch Redux Action to save the user to Redux Store
      dispatch(saveUser(response.user));

      // Dispatch Redux Action to save the token & refresh token to Redux Store
      dispatch(saveToken(response.token));
      dispatch(saveRefreshToken(response.refreshToken));

      // set loggedIn to true
      dispatch(setLoggedIn(true));

      // clear react hook form login details
      dispatch(clearRHFLogonDetails());

      // End logon
      dispatch(endAttemptLogon());

      // return results of the request
      return {
        user: response,
        company,
      };
    } catch (e) {
      // clear react hook form login details
      dispatch(clearRHFLogonDetails());
      dispatch(setApiError(e as IResponseError));
      dispatch(endAttemptLogon());

      // clear token in the event of an error
      dispatch(saveToken(undefined));

      // return the error from the API
      return {
        user: e as IResponseError,
      };
    }
  };
