export const SET_API_ERROR = 'global/SET_API_ERROR';
export const CLEAR_API_ERROR = 'global/CLEAR_API_ERROR';

export const SET_LOADING = 'global/SET_LOADING';
export const SET_LANGUAGE = 'global/SET_LANGUAGE';

export const CREATE_TOAST = 'global/CREATE_TOAST';
export const CLEAR_TOAST = 'global/CLEAR_TOAST';

export const SHOW_MODAL = 'global/SHOW_MODAL';

export const TOGGLE_LANGUAGE = 'global/TOGGLE_LANGUAGE';

export const RUN_JOYRIDE_TOUR = 'global/RUN_JOYRIDE_TOUR';
export const SET_CLICK_ACTION_STATUS = 'global/CLICK_ACTION_STATUS';
export const SET_SOCKET_CONNECTION_STATUS = 'SET_SOCKET_CONNECTION_STATUS';

export const SET_CURRENCY_RATES = 'SET_CURRENCY_RATES';
export const SET_CURRENCY_DATA = 'SET_CURRENCY_DATA';

export const SET_SAFIRI_CONFIGURATIONS = 'SET_SAFIRI_CONFIGURATIONS';
