import React, { useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import './scss/style.scss';

import { useSafiriTranslations } from './lib/hooks/translation/translations';
import { formTranslations } from './lib/hooks/translation';

import { IState } from './types/state';

import { ENGLISH, createFormErrors } from './constants';
import {
  LOGIN,
  REGISTER,
  PAGE404,
  PAGE500,
  HOME,
  PASSWORD_FORGOT,
  PASSWORD_RESET,
} from './constants/routes';

import { setLanguage, clearToast } from './state/global/actions';
import { getAllStaff } from './state/company/actions';
import { getAllGTFSStops, getGTFSAllRoutesData } from './state/gtfs/actions';
import { startTransitWatchSocketIO } from './lib/ably';

export const LoadingFallBack = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/login'));
const PasswordForgot = React.lazy(() => import('./views/password-forgot'));
const PasswordReset = React.lazy(() => import('./views/password-reset'));
const Register = React.lazy(() => import('./views/register'));
const Page404 = React.lazy(() => import('./views/error404'));
const Page500 = React.lazy(() => import('./views/error500'));

const App = () => {
  const { translation } = useSafiriTranslations();
  const dispatch = useDispatch();

  const { details, language, company } = useSelector(
    ({
      user: { details },
      global: { language },
      company: { details: company },
      gtfs,
    }: IState) => ({
      details,
      language,
      company,
      gtfs,
    }),
  );

  formTranslations.errors = createFormErrors(translation);

  useEffect(() => {
    const loadData = async () => {
      try {
        // load all routes data
        dispatch(getGTFSAllRoutesData());

        // load all gtfs stops
        dispatch(getAllGTFSStops());

        if (details) {
          // load all existing users for this company
          const tinNumber = company?.tinNumber || '';

          dispatch(getAllStaff(tinNumber));
        }
      } catch (error) {
        console.error('error loading data: ', error);
      }
    };

    loadData();
    void startTransitWatchSocketIO();

    // save the default language in redux store
    if (language && !language.languageTag) {
      dispatch(setLanguage(ENGLISH));
    }

    // clear / reset toast
    dispatch(clearToast());
    return () => {};
  }, []);

  return (
    <HashRouter>
      <React.Suspense fallback={LoadingFallBack}>
        <Switch>
          <Route exact path={LOGIN} component={Login} />
          <Route exact path={PASSWORD_FORGOT} component={PasswordForgot} />
          <Route exact path={PASSWORD_RESET} component={PasswordReset} />
          <Route exact path={REGISTER} component={Register} />
          <Route exact path={PAGE404} component={Page404} />
          <Route exact path={PAGE500} component={Page500} />
          <Route path={HOME}>
            {details ? <TheLayout /> : <Redirect to={LOGIN} />}
          </Route>
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
