import { handleActions } from 'redux-actions';

// logon actions
import {
  START_ATTEMPT_LOGON,
  END_ATTEMPT_LOGON,
  SET_API_ERROR,
  CLEAR_API_ERROR,
  SET_LOGGED_IN,
  SAVE_PHONE_NUMBER,
} from './action-types';
import { ILogonDefaultState } from './types';

export const defaultState: ILogonDefaultState = {
  loading: false,
  loggedIn: false,
  redirect: false,
};

// @ts-ignore
export default handleActions(
  {
    [START_ATTEMPT_LOGON]: (state) => ({
      ...state,
      loading: true,
    }),
    [END_ATTEMPT_LOGON]: (state) => ({
      ...state,
      loading: false,
    }),

    [SET_LOGGED_IN]: (state, { payload }) => ({
      ...state,
      loggedIn: payload,
    }),

    // @ts-ignore
    [SET_API_ERROR]: (state, { payload }) => ({
      ...state,
      apiError: payload,
    }),

    [SAVE_PHONE_NUMBER]: (state, { payload }) => ({
      ...state,
      phoneNumber: payload,
    }),

    [CLEAR_API_ERROR]: (state) => ({
      ...state,
      apiError: undefined,
    }),
  },
  defaultState,
);
